:root {
  --primary-color: #c2c2c2;
  --secondary-color: #02304f;
  --tertiary-color: #ffffff;
  --transparent-secondary-color: #02304f2d;
}

@media (max-width: 767px) {
  footer div {
    margin-left: 15px;
  }

  footer ul {
    margin-left: 15px;
    margin-top: 15px;
  }
}

@media (min-width: 768px) {
  footer div {
    margin-left: 15px;
  }

  footer ul {
    margin-right: 15px;
  }
}

body {
  background-color: var(--primary-color);
}

body::before {
  content: '';
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.08;
  background-image: url('../../../public/images/background.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}

footer {
  background: transparent;
  position:relative;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
  display: flex;
}

footer span {
  padding-left: 15px;
  color: var(--secondary-color);
}

.navbar {
  background: transparent;
  margin: 15px;
}

.navbar-collapse .navbar-nav .nav-link {
  padding-left: 25px;
  padding-right: 25px;
  color: var(--secondary-color);
}

.navbar-light .navbar-nav .nav-link:hover {
  color: var(--tertiary-color);
  padding-bottom: 5px;
  border-bottom: 3px solid var(--tertiary-color);
}

.active {
  color: var(--tertiary-color) !important;
  padding-bottom: 5px;
  border-bottom: 3px solid var(--tertiary-color);
}

.navbar-toggler {
  border-color: var(--secondary-color) !important;
  border-radius: 15% !important;
}

.navbar-toggler:focus {
  border-color: var(--tertiary-color) !important;
  box-shadow: none !important;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(2,48,79,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-brand {
  padding-bottom: 15px;
}

.card {
  background-color: transparent;
  border: none;
  margin: 15px;
}

.card-header {
  background-color: transparent;
  border: none;
}

.brandLogo {
  max-width: 100px;
  max-height: 100px;
}

.footer-img {
  height: 32px;
  width: 32px;
  content: url('../../../public/images/footerLogo.png');
}