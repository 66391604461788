@media (max-width: 767px) {
  .preloader {
    width: 30px;
    height: 30px;
    border: 3px solid #ffffff;
    border-top: 3px solid #00385e;
    border-radius: 50%;
    animation-name: loading;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    margin-right: 15px;
  }
}

@media (min-width: 768px) {
  .preloader {
    width: 45px;
    height: 45px;
    border: 5px solid #ffffff;
    border-top: 5px solid #00385e;
    border-radius: 50%;
    animation-name: loading;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    margin-right: 15px;
  }
}

.loading-animation {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-animation>h4 {
    margin-top: 5px;
  }
  
  @keyframes loading {
    from {
        transform: rotate(0deg);
    }
  
    to {
        transform: rotate(360deg);
    }
  }