@media (max-width: 767px) {
    #alignerCard{
        margin: 0% 10%;
    }
  }
  
  @media (min-width: 768px) {
    #alignerCard{
        margin: 0% 5%;
    }
  }

.card-title {
    color: var(--secondary-color);
    font-size: 26px;
    font-weight: bold;
}

#alignerCard {
    background-color: var(--transparent-secondary-color);
}

#alignerCard .card-header {
    margin-top: 5px;
}

#sequenceALabel, #sequenceBLabel {
    margin: 20px 0 20px 0;
}

#fileInputA, #fileInputA {
    margin: 40px 0 40px 0;
}

#uploadFileA_btn, #uploadFileB_btn {
    margin: 0 0 0 15px;
}

.download-label {
    font-weight: bold;
}

.invalid-feedback {
    margin-top: -15px;
    margin-bottom: 15px;
}

#sendData_btn {
    margin: 30px 0 30px 0;
    background-color: var(--tertiary-color);
    color: var(--secondary-color);
    font-weight: bold;
}

#uploadFileA_btn, #uploadFileB_btn {
    background-color: var(--secondary-color);
    color: var(--tertiary-color);
}

label {
    color: var(--secondary-color);
}