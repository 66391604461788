@media (max-width: 767px) {
    #contactCard{
        margin: 0% 10%;
    }
  }
  
  @media (min-width: 768px) {
    #contactCard{
        margin: 0% 30%;
    }
  }

#contactCard{
    background-color: var(--transparent-secondary-color);
}

#contactCard .card-header {
    margin-top: 5px;
}

#emailLabel, #phoneLabel, #messageLabel{
    margin: 5px 0 15px 0;
}

#messageLabel{
    height: 400px;
    resize: none;
    overflow: auto;
}

.invalid-feedback{
    margin-top: -15px;
    margin-bottom: 15px;
}

#sendMessage_btn{
    background-color: var(--tertiary-color);
    color: var(--secondary-color);
}

label {
    color: var(--secondary-color);
}